import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';

const Component = ({ approvalSides }) => {
  const form = useFormContext();
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = form;

  return (
    <>
      <Controller
        name={`ShouldBeApprovedBy`}
        rules={{
          required: {
            value: true,
            message: t('Field is required'),
          },
        }}
        render={({ field }) => (
          <>
            <MuiAutocomplete
              disableClearable
              freeSolo={false}
              onChange={(value) => {
                setValue(`ShouldBeApprovedBy`, value);
              }}
              label={t('Approval from')}
              value={field.value}
              options={approvalSides}
              required={true}
              placeholder={'Select'}
              error={errors.ShouldBeApprovedBy}
            />
          </>
        )}
      />
      <Controller
        name={`Description`}
        render={({ field }) => (
          <>
            <MuiOutlinedInput
              {...field}
              onChange={(event) => {
                field.onChange(event);
              }}
              label={t('Description')}
              value={field.value}
              disabled={false}
              textAlign={'left'}
              placeholder={t('Description')}
              error={errors.Description}
            />
          </>
        )}
      />
    </>
  );
};

export const NewDocumentForm = memo(Component);
