import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SingleDocument } from '../SingleDocument/single-document.component';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import styles from './list-documents.style.module.scss';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import dealsService from '@lib/services/deals-service';
import { usePopup } from '@lib/hooks/usePopup';
import { NewDocumentForm } from './NewDocumentForm/new-document-from';
import FormAccordion from '@organisms/Accordion/FormAccordion/form-accordion';
import { stepStatusCompleted } from '@lib/helpers/StepHelper/step-status.helper';

const Component = ({
  documents,
  canWrite,
  selectStep,
  approvalSides,
  getAllDealDocumentsHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();
  const { showPopup } = usePopup();

  const detachAllDocuments = (stepId) => {
    dealsService
      .detachAllDocuments(stepId)
      .then(() => {
        getAllDealDocumentsHandler(deal?.Id);
      })
      .catch((e) => {
        showPopup({
          state: 'error',
          message: e.response && e.response.data.message,
          timer: 4000,
        });
      });
  };

  return (
    <>
      <div className={styles.title}>{t('DealDocuments')}</div>
      {documents.map((step) => {
        return (
          <div key={step.Id}>
            <div className={styles.documentName}>
              {t(step.Name)}{' '}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <EditIcon
                  fontSize='medium'
                  style={{
                    marginLeft: '0px',
                    color: 'gray',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    selectStep(step, 'steps');
                  }}
                />
                {!!deal?.Draft && (
                  <CloseIcon
                    fontSize='medium'
                    style={{
                      marginLeft: '0px',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      detachAllDocuments(step?.StepId);
                    }}
                  />
                )}
              </div>
            </div>
            {step.Documents.map((document) => (
              <SingleDocument
                key={document.Id}
                stepDocument={document}
                canWrite={canWrite}
                getAllDealDocumentsHandler={getAllDealDocumentsHandler}
                activePage={'documents'}
                step={step}
                {...props}
              />
            ))}
            {!!deal?.Draft && !stepStatusCompleted(step?.Status) && (
              <FormAccordion
                title={t('New Document')}
                stepId={step?.StepId || step?.Settings?.Step?.Id}
                getAllDealDocumentsHandler={getAllDealDocumentsHandler}>
                <NewDocumentForm approvalSides={approvalSides} />
              </FormAccordion>
            )}
            {/* <TermsAndConditionsMessage /> */}
          </div>
        );
      })}
    </>
  );
};

export const ListDocuments = memo(Component);
