import React, { useMemo, useState, useEffect, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import dealsService from '@services/deals-service';
import { formatCountryName } from '@lib/helpers/helpers';
import { truncateSentence } from '@lib/helpers/truncate-sentence.helper';
import { useUserRole } from '@lib/hooks/useUserRole';
import { useNavigation } from '@lib/hooks/useNavigation';
import { useAppStore } from '@lib/Context';
import ContactSupport from '@atoms/ContactSupport/contact-support.component';
import Button from '@atoms/Buttons/Button';
import { MenuButton } from '@atoms/MenuButton/menu-button.component';
import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import VerticalSeparation from '@atoms/SeparationLine/VerticalSeparation/vertical-separation.component';
import Heading from '@atoms/Headings/heading.component';
import Portal from '@molecules/Portal/portal.component';
import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import CompleteCancelDeal from '@atoms/DealManagement/complete-cancel-deal.component';

import styles from './deal-inner-card.module.scss';
import './deal-inner-card.styles.scss';
import ImportIcon from '@molecules/ImportIcon/import-icon.component';
import ExportIcon from '@molecules/ExportIcon/export-icon.component';
import AddTaskIcon from '@mui/icons-material/AddTask';

function DealInnerCard({
  deal,
  getAllDealDocuments,
  backToOverview,
  handleOngoingStepClick,
  handleManagementClick,
  handleServicesOrders,
  activePage,
  setActivePage,
  handleLedger,
  setLoading,
  getDealSteps,
}) {
  const { t } = useTranslation();
  const { dispatch } = useAppStore();
  const { redirect } = useNavigation();
  const {
    isAdmin,
    isOrgAdmin,
    isSeller,
    isBuyer,
    isTrader,
    isFinancier,
    isInsurer,
    isWarehouseAgent,
  } = useUserRole();

  // const [tooltipMessage, setTooltipMessage] = useState('');
  // const [disableTooltip, setDisableTooltip] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [openRelatedDeal, setOpenRelatedDeal] = useState(false);
  const [completeOrCancelDeal, setCompleteOrCancelDeal] = useState(null);

  const buyerTruncate = 80;

  // useEffect(() => {
  //   if (!deal.DraftStatus) {
  //     if (deal.DraftStatus === null) {
  //       setDisableTooltip(true);
  //       return;
  //     }
  //     setTooltipMessage(t('Syncing for identifier from the platform'));
  //   } else {
  //     setTooltipMessage(t('Set Buyer and Seller to exit Draft status'));
  //   }
  // }, [deal.DraftStatus]);

  const restoreArchivedDeal = () => {
    dealsService.completeDeal(deal.Id, 1).then(() => redirect('/'));
  };

  const publishDeal = async () => {
    setLoading(true);
    dealsService
      .publishDeal(deal?.Id)
      .then(() => {
        getDealSteps();
        getAllDealDocuments();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const buyerInfo = useMemo(() => {
    const organization = deal?.Buyer ? `(${deal?.Buyer})` : '';
    const name = deal?.BuyerUser || '';

    return `${name}  ${organization}`;
  }, []);

  const handleCreateRelatedDeal = (action) => {
    if (action === 'iWantToSell') {
      redirect('/create-deal-sell', { state: { relatedDeal: deal.Id } });
    } else if (action === 'iWantToBuy') {
      redirect('/create-deal-buy', { state: { relatedDeal: deal.Id } });
    }
  };

  const handleCancelDeal = () => {
    setCompleteOrCancelDeal('cancel');
  };

  const handleCompleteDeal = () => {
    setCompleteOrCancelDeal('complete');
  };

  const menuItems = useMemo(() => {
    const items = [];

    if (!deal.Archived) {
      items.push({
        name: t('Settings'),
        icon: SettingsIcon,
        onClick: () => {
          redirect(`/edit-deal/${deal.Id}`);
          setActivePage('settings');
        },
      });
    }

    if (!!deal?.Draft && isAdmin) {
      items.push({
        name: t('PublishDeal'),
        icon: AddTaskIcon,
        onClick: () => {
          publishDeal();
        },
      });
    }

    if (!deal?.Draft) {
      items.push({
        name: t('CreateRelatedDeal'),
        icon: AddLinkIcon,
        onClick: () => setOpenRelatedDeal(true),
      });
    }

    if (isAdmin) {
      items.push({
        name: t('Ledger'),
        icon: SummarizeIcon,
        onClick: () => {
          handleLedger();
          setActivePage('ledger');
        },
      });
    }

    if (!!deal.CanFinishDeal) {
      items.push({
        name: 'Divider',
        divider: true,
      });

      items.push({
        name: t('MarkDealAsCancelled'),
        icon: HighlightOffIcon,
        iconProps: { className: styles.dealIconCancelled },
        onClick: handleCancelDeal,
      });

      items.push({
        name: t('MarkDealAsCompleted'),
        icon: CheckCircleOutlineIcon,
        iconProps: { className: styles.dealIconCompleted },
        onClick: handleCompleteDeal,
      });
    }

    return items;
  }, [deal, t, redirect, setActivePage, setOpenRelatedDeal, isAdmin]);

  const renderServicesFee = (dealId) => {
    //setLoading(true);

    dealsService
      .getServicesData(dealId)
      .then((r) => {
        handleServicesOrders(r.data.items);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (deal.CanViewDeal === false) {
      renderServicesFee(deal.Id);
    }
  }, []);

  // useEffect(() => {
  //   if (deal.CanViewDeal === false) {
  //     setActivePage('orders');
  //     handleServicesOrders();
  //   }
  // }, []);

  return (
    <div>
      {isConfirmationVisible && (
        <ConfirmationPopup
          message={t('Are you sure you want to restore the deal?')}
          cancelationHandler={() => {
            setIsConfirmationVisible(false);
          }}
          agreementHandler={restoreArchivedDeal}
        />
      )}
      <CompleteCancelDeal
        dealName={deal.Name}
        dealId={deal.Id}
        dealPopup={completeOrCancelDeal}
        setCompleteOrCancelDeal={setCompleteOrCancelDeal}
      />
      <div className={`deal-inner-card--wrap`}>
        <div className={styles.top}>
          {/*Buyer Info*/}
          <div className={styles.buyerInfo}>
            {/*Muted Icon*/}
            {deal.EmailsMuted && (isAdmin || isOrgAdmin || isTrader) ? (
              <div className={styles.mutedEmailWrap}>
                <div className={styles.mutedEmail}>
                  <UnsubscribeIcon className={styles.mutedEmailIcon} />
                </div>
              </div>
            ) : null}
            <TooltipComponent
              text={buyerInfo}
              disable={buyerInfo.length <= buyerTruncate}>
              <strong>{t('Buyer')}: </strong>

              {truncateSentence(buyerInfo, buyerTruncate)}
            </TooltipComponent>
          </div>

          <div className={styles.codeDateWrap}>
            {deal.Code} | {deal.CreateDate}
          </div>
        </div>

        <div className={styles.titleWrapper}>
          {deal.UserTypeInDeal === 'buyer' && (
            <TooltipComponent text={t('You are a buyer')} disable={false}>
              <ImportIcon />
            </TooltipComponent>
          )}
          {deal.UserTypeInDeal === 'seller' && (
            <TooltipComponent text={t('You are a seller')} disable={false}>
              <ExportIcon />
            </TooltipComponent>
          )}
          <div className={styles.title}>
            <div>{deal?.Name}</div>
          </div>
        </div>
        {openRelatedDeal && (
          <Portal overlay={'overlay'}>
            <PopupLayout
              onConfirmClick={(action) => handleCreateRelatedDeal(action)}
              closePopUp={() => setOpenRelatedDeal(false)}
              // disabled={disableButton}
              buttons={[
                {
                  buttonColor: 'quaternary',
                  buttonType: 'fill',
                  buttonName: 'WantToSell',
                  action: 'iWantToSell',
                },
                {
                  buttonColor: 'default',
                  buttonType: 'fill',
                  buttonName: 'WantToBuy',
                  action: 'iWantToBuy',
                },
              ]}>
              <Heading type={'h3'}>
                <NotificationIcon emailsMuted={deal.EmailsMuted} />
                <Trans
                  i18nKey='CreateRelatedDealPopupTitle'
                  values={{ dealName: deal.Name }}
                />
              </Heading>

              <div>{t('WhatWouldYouLikeToDo')}</div>
            </PopupLayout>
          </Portal>
        )}

        {/*Contact support*/}
        {deal.SupportAgent && (isBuyer || isSeller) && (
          <div className={styles.subTitle}>
            <ContactSupport
              contact={deal.SupportAgent}
              title={`${formatCountryName(
                deal.SupportAgent?.Country || ''
              )} ${t('Support')}`}
            />
          </div>
        )}

        {/*Buttons*/}
        <div className={styles.buttonsWrap}>
          <div className={styles.buttonInnerWrap}>
            {(isAdmin || isOrgAdmin) && !!deal.Archived && (
              <span
                className={`${activePage === 'restore' ? styles.active : ''}`}>
                <Button
                  icon='assignment'
                  type='link'
                  size='small'
                  onClick={() => {
                    setIsConfirmationVisible(true);
                    setActivePage('restore');
                  }}>
                  {t('Restore')}
                </Button>
                <VerticalSeparation type={'dark'} />
              </span>
            )}
            {deal.CanViewDeal && (
              <>
                {/* <span
                  className={`${activePage === 'steps' ? styles.active : ''}`}>
                  <Button
                    icon='assignment'
                    type='link'
                    size='small'
                    onClick={() => {
                      handleOngoingStepClick();
                      setActivePage('steps');
                    }}>
                    {t('DealSteps')}
                  </Button>
                  <VerticalSeparation type={'dark'} />
                </span> */}
                <span
                  className={`${
                    activePage === 'documents' ? styles.active : ''
                  }`}>
                  <Button
                    icon='assignment'
                    type='link'
                    size='small'
                    onClick={() => {
                      getAllDealDocuments();
                      setActivePage('documents');
                    }}>
                    {t('DealDocuments')}
                  </Button>
                  <VerticalSeparation type={'dark'} />
                </span>
                {deal.CanWriteInDeal === true && (
                  <span
                    className={`${
                      activePage === 'overview' ? styles.active : ''
                    }`}>
                    <Button
                      icon='assignment'
                      type='link'
                      size='small'
                      onClick={() => {
                        backToOverview(true);
                        setActivePage('overview');
                      }}>
                      {t('Overview')}
                    </Button>
                    <VerticalSeparation type={'dark'} />
                  </span>
                )}
                <span
                  className={`${
                    activePage === 'samples' ? styles.active : ''
                  }`}>
                  <Button
                    icon='assignment'
                    type='link'
                    size='small'
                    onClick={() => {
                      handleManagementClick();
                      setActivePage('samples');
                    }}>
                    {t('ProductSamples')}
                  </Button>
                  <VerticalSeparation type={'dark'} />
                </span>
              </>
            )}

            <span className={`${activePage === 'orders' ? styles.active : ''}`}>
              <Button
                icon='assignment'
                type='link'
                size='small'
                onClick={() => {
                  renderServicesFee(deal.Id);
                  setActivePage('orders');
                }}>
                {t('ServicesOrders')}
              </Button>
            </span>
          </div>

          <div className={styles.fileActions}>
            {!!menuItems.length &&
              deal.CanViewDeal &&
              deal.CanWriteInDeal &&
              !isFinancier &&
              !isWarehouseAgent &&
              !isInsurer && <MenuButton menuItems={menuItems} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(DealInnerCard);
