import React, { Fragment, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import UploadIcon from '@assets/icons/Upload';
import FieldError from '@atoms/Fields/ReactFormFields/FieldError/field-error.component';

import styles from './styled-dropzone-v2.module.scss';
import DocumentsTitle from '@organisms/Documents/DocumentsTitle/documents-title.component';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import SwitchComponent from '@molecules/Switch/switch.component';
import dealsService from '@lib/services/deals-service';
import { usePopup } from '@lib/hooks/usePopup';

const StyledDropzoneV2 = ({ onDropHandler, document, step }) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();
  const { showPopup } = usePopup();
  const [isDocumentAttached, setIsDocumentAttached] = useState(
    document?.AttachToStep ? !!document?.AttachToStep : false
  );

  const onDrop = (acceptedFiles) => {
    onDropHandler(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    onDrop,
    disabled: !!deal?.Draft ? !isDocumentAttached : false,
  });

  useEffect(() => {
    setIsDocumentAttached(
      document?.AttachToStep ? !!document?.AttachToStep : false
    );
  }, [document]);

  const changeDocumentAttachment = () => {
    const payload = {
      AttachToStep: !isDocumentAttached ? 1 : 0,
    };
    dealsService
      .updateAttachedDocument(step?.StepId, document?.Id, payload)
      .catch((e) => {
        showPopup({
          state: 'error',
          message: e.response && e.response.data.message,
          timer: 4000,
        });
      });
  };

  const allowedFormats = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'jpg'];

  return (
    <div className={styles.uploadFileContainer}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`${styles.dropzone} ${
            isDragAccept ? `${styles.accept}` : ''
          } ${isDragReject ? `${styles.reject}` : ''} ${
            !!deal?.Draft && !isDocumentAttached ? `${styles.disabled}` : ''
          }`}>
          <div style={{ width: '55%' }}>
            <DocumentsTitle
              document={document}
              step={step}
              file={document?.Files[0]}
              type={'attach'}
              showInfo={true}
            />
          </div>
          <UploadIcon />

          {isDragActive && isDocumentAttached ? (
            <div className={styles.text}>
              <h5>{t('Drop the files here')} ...</h5>
            </div>
          ) : (
            <div className={styles.text}>
              <h5>
                <div>
                  <Trans i18nKey='DragAndDrop' components={{ p: <span /> }} />
                </div>
              </h5>
              <p>
                {t('Supported file formats')}:{' '}
                <span className={'lowercase'}>
                  {allowedFormats?.map((format, i) => {
                    // map the formats
                    if (i < allowedFormats?.length - 1) {
                      return <Fragment key={i}>{format}, </Fragment>;
                    } else {
                      return <Fragment key={i}>{format}</Fragment>;
                    }
                  })}
                </span>
              </p>
              <p>
                <Trans i18nKey='FileLimit' values={{ size: 50 }} />
              </p>
            </div>
          )}
        </div>
      </div>
      {!!deal?.Draft && (
        <div className={styles.toggle}>
          <SwitchComponent
            values={['', '']}
            onChange={() => {
              setIsDocumentAttached(!isDocumentAttached);
              changeDocumentAttachment();
            }}
            checked={isDocumentAttached}
            height={20}
            width={40}
            handleDiameter={15}
            offColor={'#333'}
          />
        </div>
      )}

      <div className={'upload-file__errors'}>
        {fileRejections.length > 0 && (
          <FieldError
            error={
              fileRejections[0].errors[0].code === 'file-invalid-type'
                ? 'The type of the file is incorrect'
                : fileRejections[0].errors[0].message
            }
          />
        )}
      </div>
    </div>
  );
};

export default StyledDropzoneV2;
