import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListDocuments } from '../ListDocuments/list-documents.component';
import StepDocuments from '../StepDocuments/step-documents.component';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import ProductButtons from '@molecules/ButtonsVariation/ProductButtons/product-buttons.component';
import TermsAndConditionsMessage from '@organisms/TermsAndConditions/terms-and-conditions-message.component';
import AdditionalDocuments from '@organisms/Documents/AdditionalDocuments/additional-documents.component';
import { stepStatusRejected } from '@base/globalVariables/global-variables';

const DocumentsSection = ({
  documents,
  loadStep,
  currentStep,
  showAllDocuments,
  stepActions,
  onSubmit,
  currentInnerStep,
  selectStep,
  getAllDealDocumentsHandler,
}) => {
  const { t } = useTranslation();
  const {
    documents: dealDocumentsContext,
    selectedStep: selectedStepContext,
    canWrite,
    documentApprovalSides,
  } = useManageDealContext();

  const [error, setError] = useState(null);
  const [fileInitialVersion, setFileInitialVersion] = useState('');

  const stepGeneratedDocument = useMemo(() => {
    // in case of inner steps inside the step
    if (Number(currentInnerStep) >= 0) {
      const temp = dealDocumentsContext || documents;
      const tempDocument = temp.filter((document, index) => {
        if (currentInnerStep === index) {
          return (
            document?.Name ===
            (selectedStepContext?.StepDocument || currentStep?.StepMainDocument)
          );
        }
      });

      return tempDocument[0];
    } else {
      const temp = dealDocumentsContext || documents;
      const tempDocument = temp?.filter((document) => {
        return (
          document?.Name ===
          (selectedStepContext?.StepDocument || currentStep?.StepMainDocument)
        );
      });

      return tempDocument[0];
    }
  }, [dealDocumentsContext, selectedStepContext, currentInnerStep]);

  //fixes for https://almacenaplatform.atlassian.net/browse/ATE-2024
  useEffect(() => {
    if (currentStep?.Status === stepStatusRejected) {
      if (
        currentStep?.StepMainDocument === stepGeneratedDocument?.Name &&
        stepGeneratedDocument?.Files[0]
      ) {
        setFileInitialVersion(stepGeneratedDocument?.Files[0]?.VersionNumber);
      }
    }
  }, []);

  const handleDocumentSubmit = (actionType) => {
    if (stepGeneratedDocument && stepGeneratedDocument?.Files) {
      if (
        stepGeneratedDocument?.Files.length === 0 ||
        fileInitialVersion === stepGeneratedDocument?.Files[0]?.VersionNumber
      ) {
        setError({
          message: t('ValidationMissingAttachDocument'),
        });
      } else if (
        stepGeneratedDocument?.Files?.length > 0 ||
        stepGeneratedDocument[0]?.Files?.length > 0 ||
        selectedStepContext.StepAbbreviation === 'PAY'
      ) {
        const payload = {
          ButtonType: actionType,
        };

        setError(null);
        onSubmit(payload);
      }
    } else {
      setError({
        message: t('ValidationMissingAttachDocument'),
      });
    }
  };

  return (
    <>
      {showAllDocuments ? (
        <ListDocuments
          documents={documents}
          selectStep={selectStep}
          canWrite={canWrite}
          getAllDealDocumentsHandler={getAllDealDocumentsHandler}
          approvalSides={documentApprovalSides}
        />
      ) : stepGeneratedDocument ? (
        <StepDocuments
          documents={stepGeneratedDocument}
          step={currentStep}
          error={error}
          canWrite={canWrite}
        />
      ) : (
        <></>
      )}
      {!!stepActions && (
        <ProductButtons
          newHandleSubmit={handleDocumentSubmit}
          handleSubmit={handleDocumentSubmit}
          stepActions={stepActions}
        />
      )}
      <TermsAndConditionsMessage />
      {!showAllDocuments && (
        <AdditionalDocuments currentStep={currentStep} canWrite={canWrite} />
      )}
    </>
  );
};

export default memo(DocumentsSection);
