import { httpClient } from "./httpClient";

const RESOURCE_PATH = "api-deal";

const dealsService = {
  getDeals: function (filter, restParams) {
    const params = {
      PerPage: 10,
      Keywords: filter,
      ...restParams,
    };
    const responseType = params.Export ? "arraybuffer" : undefined;
    return httpClient.get(`${RESOURCE_PATH}`, { params, responseType });
  },

  getDealSteps: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/steps`);
  },

  getServicesData: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/getServices`);
  },

  getStepDetails: function (dealId, stepId, param) {
    return httpClient.get(
      `${RESOURCE_PATH}/${dealId}/stepDetails/${stepId}${
        param ? `?paymentReturn=${param}` : ""
      }`
    );
  },

  getCreateDeal: function () {
    return httpClient.get(`${RESOURCE_PATH}/createDeal`);
  },

  getUpdateDeal: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/updateDeal`);
  },

  updateStep: function (dealId, stepId, data) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/updateStep/${stepId}`,
      data
    );
  },

  validateUpdateStep: function (dealId, stepId, data) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/validateUpdateStep/${stepId}`,
      data
    );
  },

  updateServicesData: function (dealId, data) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/updateServices`, data);
  },

  updateDeal: function (dealId, data) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/updateDeal`, data);
  },

  createNewSample: function (dealId, data) {
    return httpClient.post(`${RESOURCE_PATH}/${dealId}/createNewSample`, data);
  },

  createNewPCSample: function (dealId, data) {
    return httpClient.post(
      `${RESOURCE_PATH}/${dealId}/createNewPriceConfirmation`,
      data
    );
  },

  completeDeal: function (dealId, status) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/completeDeal`, {
      Status: status,
    });
  },

  addProductToInventory: function (dealId, productId, icoNumber, lotId) {
    return httpClient.post(
      `${RESOURCE_PATH}/${dealId}/addToProductInventory/${productId}`,
      {
        icoNumber: icoNumber,
        lotId: lotId,
      }
    );
  },

  deleteDeal: function (dealId) {
    return httpClient.delete(`${RESOURCE_PATH}/${dealId}`);
  },

  userOrganization: function (data) {
    return httpClient.put(`${RESOURCE_PATH}/userOrganization`, data);
  },

  createDeal: function (data) {
    return httpClient.post(`${RESOURCE_PATH}/createDeal`, data);
  },

  deleteStep: function (dealId, stepId) {
    return httpClient.delete(`${RESOURCE_PATH}/${dealId}/deleteStep/${stepId}`);
  },

  sync: function (dealId) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/syncInventory`, {});
  },

  verifyInvitation: function (hash) {
    return httpClient.get(`${RESOURCE_PATH}/verifyInvitation/${hash}`);
  },

  getInventoryLotsByProductId: function ({ dealId, productId }) {
    return httpClient.get(
      `${RESOURCE_PATH}/${dealId}/productLots/${productId}`
    );
  },

  getContracts: function (dealId, productId, productSku, productType) {
    const payload = {
      DealId: dealId,
      ProductId: productId,
      ProductSku: productSku,
      PriceType: productType,
    };

    return httpClient.put(`${RESOURCE_PATH}/productContracts`, payload);
  },
  getLedgerLogs: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/ledgerLog`);
  },
  createLedgerLog: function (dealId, payload) {
    return httpClient.post(
      `${RESOURCE_PATH}/${dealId}/createLedgerLog`,
      payload
    );
  },
  updateLedgerLog: function (dealId, logId, payload) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/updateLedgerLog/${logId}`,
      payload
    );
  },
  settleLedgerLog: function (dealId, logId, payload) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/settleLedgerLog/${logId}`,
      payload
    );
  },
  deleteLedgerLog: function (dealId, logId) {
    return httpClient.delete(
      `${RESOURCE_PATH}/${dealId}/deleteLedger/${logId}`
    );
  },
  getLedgerLogTypes: function () {
    return httpClient.get(`${RESOURCE_PATH}/ledgerLogTypes`);
  },
  publishDeal: function (dealId) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/publishDeal`);
  },
  attachDocumentToStep: function (stepId, payload) {
    return httpClient.post(
      `${RESOURCE_PATH}/${stepId}/attachDocumentToStep`,
      payload
    );
  },
  updateAttachedDocument: function (stepId, documentId, payload) {
    return httpClient.put(
      `${RESOURCE_PATH}/${stepId}/updateAttachedDocument/${documentId}`,
      payload
    );
  },
  detachAllDocuments: function (stepId) {
    return httpClient.delete(
      `${RESOURCE_PATH}/${stepId}/detachDocumentsFromStep`
    );
  },
};

export default dealsService;
