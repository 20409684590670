import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DocumentsTitle from '@organisms/Documents/DocumentsTitle/documents-title.component';
import StepDocuments from '@organisms/Documents/StepDocuments/step-documents.component';
import Button from '@atoms/Button/button.component';

import styles from './step-other-documents.module.scss';

const StepOtherDocuments = ({
  documents,
  step,
  canWrite,
  updateDocuments,
  activePage,
}) => {
  const { t } = useTranslation();

  const [currentDocuments, setCurrentDocuments] = useState(documents);
  const [openedDocuments, setOpenedDocuments] = useState([]);

  useEffect(() => {
    const openedDocumentsArray = [];
    if (!!documents.length) {
      documents.map((r) => {
        if (r?.Files.length > 0) {
          openedDocumentsArray.push(r.Id);
        }
      });

      setCurrentDocuments([...documents]);
    }
    setOpenedDocuments(openedDocumentsArray);
  }, [documents]);

  const controlDocumentDropdown = (document) => {
    if (openedDocuments.length > 0 && openedDocuments.includes(document?.Id)) {
      const temp = openedDocuments.filter((r) => r !== document?.Id);

      setOpenedDocuments(temp);
    } else {
      setOpenedDocuments([...openedDocuments, document?.Id]);
    }
  };

  return (
    <div className={styles.relatedDocuments}>
      {currentDocuments.map((document, index) => (
        <Fragment key={index}>
          <div
            className={`${styles.stepTitle} ${
              openedDocuments.includes(document?.Id)
                ? styles.open
                : styles.close
            }`}>
            <DocumentsTitle
              document={document}
              step={step}
              file={document?.Files[0]}
              type={'attach'}
            />

            <Button
              type={'link'}
              onClick={() => controlDocumentDropdown(document)}>
              {t('Attach')}
            </Button>
          </div>

          {openedDocuments.includes(document?.Id) && (
            <StepDocuments
              documents={document}
              step={step}
              view={'all'}
              canWrite={canWrite}
              updateDocuments={updateDocuments}
              activePage={activePage}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(StepOtherDocuments);
