/**
 * Variables used in JS
 */

export const isSearchVisible = 10;
// Media Queries
export const smallMobileMediaQuery = 480;
export const tabletMediaQuery = 1024;
export const smallDesktopMediaQuery = 1250;
// Step Statuses
export const stepStatusPendingApproval = "pendingApproval";
export const stepStatusApproved = "approved";
export const stepStatusRejected = "rejected";
export const stepStatusActive = "active";
//Step Abbreviation
export const APRAbbr = "APR";
export const PCAbbr = "PC";
export const PCEAbbr = "PCE";
