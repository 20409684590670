/**
 * Documents Title Section component
 * @description: returns the user back to the previous page he has been
 * @param step: info with the current step
 * @param stepSettings: settings of the current step
 * @param setExecutionTypePayload: send to the parent setState of the switcher payload.
 */

import React, { useEffect, useMemo, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import SwitchComponent from '@molecules/Switch/switch.component';
import DocumentsTitle from '@organisms/Documents/DocumentsTitle/documents-title.component';

import styles from './documents-title-section.module.scss';

const DocumentsTitleSection = ({
  step,
  stepSettings,
  setExecutionTypePayload,
  currentInnerStep,
}) => {
  const { documents: dealDocumentsContext } = useManageDealContext();
  const [documentView, setDocumentView] = useState(false); // false === generate, true === attach

  // assign the switcher
  useEffect(() => {
    if (step?.ExecutionType?.Type === 'Generate') {
      setDocumentView(false);
    } else if (step?.ExecutionType?.Type === 'Attach') {
      setDocumentView(true);
    }
  }, [step]);

  const executionTypePayload = useMemo(() => {
    if (documentView) {
      return 'Attach';
    } else {
      return 'Generate';
    }
  }, [documentView]);

  useEffect(() => {
    setExecutionTypePayload(executionTypePayload);
  }, [documentView]);

  // find the generated documents from the list of documents and send it to <DocumentsTitle />
  const stepGeneratedDocument = useMemo(() => {
    if (Number(currentInnerStep) >= 0) {
      const tempDocument = dealDocumentsContext.filter((document, index) => {
        if (currentInnerStep === index) {
          return (
            document?.Name ===
            (stepSettings?.StepDocument || step?.StepMainDocument)
          );
        }
      });

      return tempDocument[0];
    } else {
      const tempDocument = dealDocumentsContext?.filter((document) => {
        return (
          document?.Name ===
          (stepSettings?.StepDocument || step?.StepMainDocument)
        );
      });

      return tempDocument[0] || null;
    }
  }, [dealDocumentsContext, stepSettings, currentInnerStep]);

  return (
    <>
      {stepGeneratedDocument && (
        <div className={styles.stepTitle}>
          <DocumentsTitle
            document={stepGeneratedDocument}
            step={step}
            file={stepGeneratedDocument?.Files[0]}
          />
          {step?.ExecutionType?.Show && (
            <div className={styles.titleSwitcherWrap}>
              <SwitchComponent
                values={['Generate', 'Attach']}
                onChange={() => setDocumentView(!documentView)}
                checked={documentView || false}
                uncheckedHandleIcon={<SettingsIcon />}
                checkedHandleIcon={<AttachFileIcon />}
                disabled={step?.ExecutionType?.Disabled}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentsTitleSection;
