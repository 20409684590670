/**
 * Documents Title component
 * @description:
 * @param type = attach | null: used to show a different text inside the subTitle area.
 */

import React, { useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import DocumentHistoryPopup from '@organisms/Documents/DocumentFile/DocumentsHistoryPopup/document-history-popup.component';
import Tooltip from '@atoms/Tooltip/tooltip.component';
import styles from './documents-title.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const DocumentsTitle = ({ document, step, file, type, showInfo }) => {
  const { t } = useTranslation();

  const [uploadDocsDialog, setUploadDocsDialog] = useState(false);

  const mainTitle = useCallback(() => {
    if (document) {
      if (showInfo) {
        return (
          <div className={styles.titleWithIcon}>
            <>{t(document?.Name)}</>
            <Tooltip text={document?.Description || t('NoDescription')}>
              <InfoOutlinedIcon
                style={{ marginLeft: '6px' }}
                fontSize='small'
              />
            </Tooltip>
          </div>
        );
      } else {
        return <>{t(document?.Name)}</>;
      }
    } else {
      switch (step.StepAbbreviation) {
        case 'PAY':
          return <>{t('Instructions')}</>;
        case 'PROOF':
          return <>{t('Payment Confirmation')}</>;
      }
    }
  }, [step, document]);

  const renderSubtext = useCallback(() => {
    if (type === 'attach') {
      return <p>{t('PleaseAttachDocument')}</p>;
    } else if (document) {
      if (step.StepAbbreviation === 'PROOF') {
        return <p>{t('PleaseGenerateDocument')}</p>;
      } else {
        return <p>{t('PleaseGenerateOrAttachDocument')}</p>;
      }
    }
  }, [document, type]);

  return (
    <div className={styles.titleInnerWrap}>
      <div className={styles.titleIconWrap}>
        {document && document?.Files?.length > 0 ? (
          <ContentPasteSearchIcon
            className={styles.list}
            onClick={() => setUploadDocsDialog(true)}
          />
        ) : (
          <FileCopyIcon className={styles.fileIcon} />
        )}
        {uploadDocsDialog && (
          <DocumentHistoryPopup
            title={t('ManageDocuments')}
            isEdit={true}
            setUploadDocsDialog={setUploadDocsDialog}
            step={step}
            file={file}
            stepDocument={document}
            onRefreshDocuments={() => setUploadDocsDialog(false)}
          />
        )}
      </div>
      <div className={styles.titleWrap}>
        <h3>{mainTitle()}</h3>
        {renderSubtext()}
      </div>
    </div>
  );
};

export default memo(DocumentsTitle);
