import React, { useEffect, useState, useCallback, useMemo } from 'react';
import parse from 'html-react-parser';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SelectInput from 'Components/Shared/SelectInput';
import { useUserRole } from '@lib/hooks/useUserRole';
import dealsService from '@lib/services/deals-service';
import { useDebounce } from '@lib/hooks/useDebounce';
import { isValueIncludedInArray, required } from '@lib/helpers/validation';
import CheckmarkIcon from '@assets/icons/Checkmark';
import variables from '@base/styles/variables.scss';
import FieldError from '@atoms/Fields/ReactFormFields/FieldError/field-error.component';
import BackButton from '@atoms/Buttons/back-button.component';
import Button from '@atoms/Button/button.component';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import EmptySelect from '@atoms/EmptySelect/empty-select.component';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import { BuyerAgents } from './BuyerAgents/buyer-agents.component';
import { SellerAgents } from './SellerAgents/seller-agents.component';
import './edit-deal.styles.scss';
import styles from './edit-deal.module.scss';
import { SupportAgents } from './SupportAgents/support-agents.component';
import { InsuranceAgents } from './InsuranceAgents/insurance-agents.component';
import { FinanceAgents } from './FinanceAgents/finance-agents.component';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { WarehouseAgents } from './WarehouseAgents/warehouse-agents.component';

const EditDealComponent = ({
  errors,
  deal,
  user,
  sellerInfo,
  sellerEmailInfo,
  sellerId,
  buyerInfo,
  buyerEmailInfo,
  buyerId,
  traders,
  traderId,
  onSubmitHandler,
  loader,
  mutedEmailProp,
  showContactInfoProp,
  steps,
  feeProducts,
  buyerAgents,
  sellerAgents,
  roleInDeal,
  blockchainVerificationValue,
  permissionsList,
  financeAgents,
  financierId,
  insuranceAgents,
  insurerId,
  warehouseAgents,
  warehouseAgentId,
}) => {
  const { t } = useTranslation();

  const [references, setReferences] = useState();
  const [myReference, setMyReference] = useState();
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [muteMail, setMuteMail] = useState(true);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [blockchainVerification, setBlockchainVerification] = useState(false);
  const [traderLabel, setTraderLabel] = useState([]);
  const [financeLabel, setFinanceLabel] = useState([]);
  const [insuranceLabel, setInsuranceLabel] = useState([]);
  const [warehouseLabel, setWarehouseLabel] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState([]);
  const [name, setName] = useState('');
  const [timer, setTimer] = useState(null);
  const [buyer, setBuyer] = useState('');
  const [seller, setSeller] = useState('');
  const [inputBuyerLoader, setInputBuyerLoader] = useState(false);
  const [buyerOrganizationMessage, setBuyerOrganizationMessage] = useState('');
  const [buyerOrganizationId, setBuyerOrganizationId] = useState('');
  const [inputSellerLoader, setInputSellerLoader] = useState(false);
  const [sellerOrganizationId, setSellerOrganizationId] = useState('');
  const [sellerOrganizationMessage, setSellerOrganizationMessage] =
    useState('');
  const [productsFee, setProductsFee] = useState([]);
  const [supportAgents, setSupportAgents] = useState([]);
  const { isAdmin, isOrgAdmin, isTrader } = useUserRole();
  const { debounce } = useDebounce();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      BuyerAgents:
        buyerAgents?.map((user) => ({
          User: user.User,
          Write: user.Write, //permissionsList.find((a) => user.Write === a.value),
          ViewDeal: 1,
        })) || [],
      SellerAgents:
        sellerAgents?.map((user) => ({
          User: user.User,
          Write: user.Write,
          ViewDeal: 1,
        })) || [],
      SupportAgents:
        traderId?.map((user) => ({
          User: user.User,
          Write: user.Write,
          ViewDeal: user.ViewDeal === 1 ? true : false,
        })) || [],
      FinanceAgents:
        financierId?.map((user) => ({
          User: user.User,
          Write: user.Write,
          ViewDeal: user.ViewDeal === 1 ? true : false,
        })) || [],
      InsuranceAgents:
        insurerId?.map((user) => ({
          User: user.User,
          Write: user.Write,
          ViewDeal: user.ViewDeal === 1 ? true : false,
        })) || [],
      WarehouseAgents:
        warehouseAgentId?.map((user) => ({
          User: user.User,
          Write: user.Write,
          ViewDeal: user.ViewDeal === 1 ? true : false,
        })) || [],
    },
  });

  const {
    handleSubmit,
    formState: { dirtyFields, errors: formErrors },
    setValue,
    getValues,
    clearErrors,
  } = form;

  useEffect(() => {
    sellerEmailInfo && setSeller(sellerEmailInfo);
    setSellerOrganizationId(sellerId);
  }, [sellerId]);

  useEffect(() => {
    if (user && (user.isAdmin || user.isOrgAdmin) && sellerEmailInfo) {
      const info = sellerInfo
        ? `<b>${sellerInfo.name}</b> ${t('from')} <b>${
            sellerInfo.organization
          }</b>`
        : `<b>${sellerInfo.name}</b> ${t('from')} <b>N/A</b>`;
      setSellerOrganizationMessage(info);
    }
  }, [sellerInfo]);

  useEffect(() => {
    if (user && (user.isAdmin || user.isOrgAdmin) && buyerEmailInfo) {
      const info = buyerInfo
        ? `<b>${buyerInfo.name}</b> ${t('from')} <b>${
            buyerInfo.organization
          }</b>`
        : `<b>${buyerInfo.name}</b> ${t('from')} <b>N/A</b>`;
      setBuyerOrganizationMessage(info);
    }
  }, [buyerInfo]);

  useEffect(() => {
    setBuyer(buyerEmailInfo);
    setBuyerOrganizationId(buyerId);
  }, [buyerId]);

  useEffect(() => {
    let selectedStepsArr = [];
    steps.map((step) => step.Selected && selectedStepsArr.push(step.StepId));
    setSelectedSteps(selectedStepsArr);
  }, [steps]);

  useEffect(() => {
    if (deal) {
      setReferences(deal.References);
      setMyReference(deal.MyReference);
      setName(deal.Name || '');
    }
  }, [deal]);

  useEffect(() => {
    if (traders && traders[0]) {
      const labels = Object.keys(traders[0]).map((key) => traders[0][key]);
      setTraderLabel(labels);
    }
  }, [traders]);

  useEffect(() => {
    if (insuranceAgents && insuranceAgents[0]) {
      const labels = Object.keys(insuranceAgents[0]).map(
        (key) => insuranceAgents[0][key]
      );
      setInsuranceLabel(labels);
    }
  }, [insuranceAgents]);

  useEffect(() => {
    if (financeAgents && financeAgents[0]) {
      const labels = Object.keys(financeAgents[0]).map(
        (key) => financeAgents[0][key]
      );
      setFinanceLabel(labels);
    }
  }, [financeAgents]);

  useEffect(() => {
    if (warehouseAgents && warehouseAgents[0]) {
      const labels = Object.keys(warehouseAgents[0]).map(
        (key) => warehouseAgents[0][key]
      );
      setWarehouseLabel(labels);
    }
  }, [warehouseAgents]);

  useEffect(() => {
    setProductsFee(feeProducts);
  }, [feeProducts]);

  // get the value of the muted Email switcher.
  useEffect(() => {
    user &&
      (user.isAdmin || user.isOrgAdmin) &&
      setMuteMail(mutedEmailProp === 1 ? true : false);
  }, [mutedEmailProp]);

  useEffect(() => {
    user &&
      (user.isAdmin || user.isOrgAdmin) &&
      setShowContactInfo(showContactInfoProp === 1 ? true : false);
  }, [showContactInfoProp]);

  useEffect(() => {
    setBlockchainVerification(blockchainVerificationValue === 1 ? true : false);
  }, [blockchainVerificationValue]);

  const onChangeReference = (index, reference) => {
    let arr = [...references];
    arr[index].Reference = reference;
    setReferences(arr);
  };

  const onChangeReferenceType = (index, type) => {
    let arr = [...references];
    arr[index].Type = type;
    setReferences(arr);
  };

  const addReference = () => {
    let arr = [...references];
    arr.push({
      Id: null,
      Type: '',
      Reference: '',
    });
    setReferences(arr);
  };

  const deleteReference = (index) => {
    let arr = [...references];
    arr.splice(index, 1);
    setReferences(arr);
  };

  const sortSteps = () => {
    if (selectedSteps.length > 0) {
      let stepsArray = selectedSteps;
      stepsArray.sort(function (a, b) {
        return a - b;
      });
      setSelectedSteps(stepsArray);
    }
  };

  // check if we have a duplicates reference Types.
  const cheIfDuplicateExists = (arr) => {
    const valueArr = arr?.map((item) => {
      return item.Type;
    });
    const isDuplicate = valueArr?.some((item, idx) => {
      return valueArr?.indexOf(item) != idx;
    });

    return isDuplicate;
  };

  const submit = (formData) => {
    const BuyerAgents = formData.BuyerAgents.map((agent) => {
      return { User: agent.User, Write: Number(agent.Write), ViewDeal: 1 };
    });
    const SellerAgents = formData.SellerAgents.map((agent) => {
      return { User: agent.User, Write: Number(agent.Write), ViewDeal: 1 };
    });
    const SupportAgents = formData.SupportAgents.map((agent) => {
      return {
        User: agent.User,
        Write: Number(agent.Write),
        ViewDeal: agent.ViewDeal === true ? 1 : 0,
      };
    });
    const InsuranceAgents = formData.InsuranceAgents.map((agent) => {
      return {
        User: agent.User,
        Write: Number(agent.Write),
        ViewDeal: agent.ViewDeal === true ? 1 : 0,
      };
    });
    const FinanceAgents = formData.FinanceAgents.map((agent) => {
      return {
        User: agent.User,
        Write: Number(agent.Write),
        ViewDeal: agent.ViewDeal === true ? 1 : 0,
      };
    });
    const WarehouseAgents = formData.WarehouseAgents.map((agent) => {
      return {
        User: agent.User,
        Write: Number(agent.Write),
        ViewDeal: agent.ViewDeal === true ? 1 : 0,
      };
    });

    const convertMuteMail = muteMail ? 1 : 0;
    let refs;

    sortSteps();
    if (user && (user.isAdmin || user.isOrgAdmin) === true) {
      refs = [];
      references.map((el) => {
        refs.push({
          Id: el.Id,
          ReferenceType: el.Type,
          Reference: el.Reference,
        });
      });
    } else {
      refs = {};
      refs[deal.UserTypeInDeal] = myReference;
    }

    if (user && !(user.isAdmin || user.isOrgAdmin)) {
      const data = {
        Title: name,
        FormSteps: selectedSteps,
        BlockchainVerification: blockchainVerification ? 1 : 0,
        ...formData,
        BuyerAgents,
        SellerAgents,
      };

      onSubmitHandler(data, refs);
    } else if (user && (user.isAdmin || user.isOrgAdmin)) {
      const data = {
        Title: name,
        FormSteps: selectedSteps,
        MuteEmails: convertMuteMail,
        ShowContactInfo: showContactInfo ? 1 : 0,
        BlockchainVerification: blockchainVerification ? 1 : 0,
        SellerId: sellerOrganizationId,
        BuyerId: buyerOrganizationId,
        FeeProducts: productsFee,
        ...formData,
        BuyerAgents,
        SellerAgents,
        TraderId: SupportAgents,
        FinancierId: FinanceAgents,
        InsurerId: InsuranceAgents,
        WarehouseAgentId: WarehouseAgents,
      };
      onSubmitHandler(data, refs);
    }
  };

  const handleFeeChange = (value, index) => {
    const handleChange = [...productsFee];
    handleChange[index] = { ...handleChange[index], FeePercentage: value };
    setProductsFee(handleChange);
  };

  const selectStep = (step) => {
    let arr = selectedSteps;

    if (arr.includes(step.StepId))
      arr = arr.filter((item) => item !== step.StepId);
    else arr = [...arr, step.StepId];

    setSelectedSteps(arr);
  };
  const validateAgentEmail = ({ value, emailType, index }) => {
    const fieldsType =
      emailType === 'BuyerEmail' ? 'BuyerAgents' : 'SellerAgents';
    setValue(`${fieldsType}[${index}].OrganizationId`, null);
    setValue(`${fieldsType}[${index}].OrganizationMessage`, '');
    setValue(`${fieldsType}[${index}].loading`, true);
    debounce(
      () =>
        dealsService
          .userOrganization({
            [emailType]: value.trim(),
            Action: 'checkAgent',
          })
          .then((res) => {
            setValue(
              `${fieldsType}[${index}].OrganizationId`,
              res.data.OrganizationId
            );
            setValue(
              `${fieldsType}[${index}].OrganizationMessage`,
              res.data.OrganizationMessage
            );
            setValue(`${fieldsType}[${index}].loading`, false);
          }),
      1000
    )();
  };

  const addedAgents = useMemo(
    () => [...getValues('BuyerAgents'), ...getValues('SellerAgents')],
    [getValues]
  );

  // call the api when the user stops typing and the mail is valid
  const buyerSellerEntry = (entry, inputName) => {
    if (inputName === 'seller') {
      setSellerOrganizationId('');
      setSellerOrganizationMessage('');
      setSeller(entry);
      setInputSellerLoader(!!entry);

      // Wait the user to stop typing for 1s and then call the api only once based on the last entry
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        dealsService
          .userOrganization({
            SellerEmail: entry.trim(),
            Action: 'updateDeal',
          })
          .then((res) => {
            setSellerOrganizationId(res.data.OrganizationId);
            setSellerOrganizationMessage(res.data.OrganizationMessage);
            setInputSellerLoader(false);
          });
      }, 1000);

      setTimer(newTimer);
    } else if (inputName === 'buyer') {
      setBuyerOrganizationId('');
      setBuyerOrganizationMessage('');
      setBuyer(entry);
      setInputBuyerLoader(!!entry);

      // Wait the user to stop typing for 1s and then call the api only once based on the last entry
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        dealsService
          .userOrganization({
            BuyerEmail: entry.trim(),
            Action: 'updateDeal',
          })
          .then((res) => {
            setBuyerOrganizationId(res.data.OrganizationId);
            setBuyerOrganizationMessage(res.data.OrganizationMessage);
            setInputBuyerLoader(false);
          });
      }, 1000);

      setTimer(newTimer);
    }
  };

  const onChangeMultiSelectHandler = useCallback((selected) => {
    const selectedIDs = selected.map((item) => item?.key);
    setSelectedTrader(selectedIDs);
  }, []);

  useEffect(() => {
    if (traders && traders[0]) {
      const agents = Object.entries(traders[0]).map(([key, value]) => ({
        key,
        value,
      }));
      setSupportAgents(agents);
    }
    if (traderId) {
      setSelectedTrader(traderId);
    }
  }, [traders, traderId]);

  return (
    <FormProvider {...form}>
      <div className='edit-deal-container'>
        <div className='head'>
          <h3>{t('DealSettings')}</h3>
        </div>
        <div className='edit-deal-body'>
          <div className={styles.inputWrapper}>
            <Controller
              name={'Title'}
              defaultValue={deal?.Name || ''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  label={'Name'}
                  required={false}
                  disabled={false}
                  textAlign={'left'}
                  placeholder={t('Type')}
                />
              )}
            />
          </div>
          {user && (isAdmin || isOrgAdmin) && (
            <div className={styles.inputWrapper}>
              <Controller
                name={'BuyerEmail'}
                defaultValue={buyerEmailInfo || ''}
                rules={{
                  ...isValueIncludedInArray(
                    [getValues('SellerEmail'), ...addedAgents],
                    t('AlreadyAddedEmail')
                  ),
                  required: {
                    value: true,
                    message: t(required.required),
                  },
                }}
                render={({ field }) => (
                  <div className={styles.inputWithIcon}>
                    <div style={{ width: isAdmin ? '93%' : '100%' }}>
                      <MuiOutlinedInput
                        {...field}
                        onChange={(event) => {
                          buyerSellerEntry(event.target.value, 'buyer');
                          field.onChange(event);
                        }}
                        label={'Buyer Email'}
                        required={true}
                        disabled={false}
                        textAlign={'left'}
                        placeholder={t('Type')}
                        error={formErrors.BuyerEmail}
                        helperText={
                          buyerOrganizationMessage && (
                            <p className='organization-name'>
                              {parse(buyerOrganizationMessage)}
                            </p>
                          )
                        }
                        end={inputBuyerLoader ? <LoadingSpinner /> : ''}
                      />
                    </div>
                    {isAdmin && (
                      <Link to={'/'} title={t('Impersonate')}>
                        <IconButton
                          style={{ padding: 8, color: 'black' }}
                          onClick={() => {
                            const setCookies = new Promise((resolve) => {
                              setCookie('impersonateEmail', field.value, {
                                encode: (v) => v,
                              });

                              resolve(cookies['impersonateEmail']);
                            });
                            setCookies.then(() => {
                              navigate('/');
                            });
                          }}>
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                      </Link>
                    )}
                  </div>
                )}
              />
            </div>
          )}

          {user && !isAdmin && !isOrgAdmin && (
            <div className={styles.inputWrapper}>
              <InputLabel label={'Buyer'} />
              <EmptySelect placeholder={buyerInfo.name} />
            </div>
          )}

          {!isTrader && (isAdmin || isOrgAdmin || roleInDeal === 'Buyer') ? (
            <div className={styles.inputWrapper}>
              <BuyerAgents
                onValidateEmail={validateAgentEmail}
                sellerEmail={sellerEmailInfo}
                permissions={permissionsList}
              />
            </div>
          ) : null}

          {user && (isAdmin || isOrgAdmin) && (
            <div className={styles.inputWrapper}>
              <Controller
                name={'SellerEmail'}
                defaultValue={sellerEmailInfo || ''}
                rules={{
                  ...isValueIncludedInArray(
                    [getValues('BuyerEmail'), ...addedAgents],
                    t('AlreadyAddedEmail')
                  ),
                  required: {
                    value: true,
                    message: t(required.required),
                  },
                }}
                render={({ field }) => (
                  <div className={styles.inputWithIcon}>
                    <div style={{ width: isAdmin ? '93%' : '100%' }}>
                      <MuiOutlinedInput
                        {...field}
                        onChange={(event) => {
                          buyerSellerEntry(event.target.value, 'seller');
                          field.onChange(event);
                        }}
                        label={'Seller Email'}
                        required={true}
                        disabled={false}
                        textAlign={'left'}
                        placeholder={t('Type')}
                        error={formErrors.SellerEmail}
                        helperText={
                          sellerOrganizationMessage && (
                            <p className='organization-name'>
                              {parse(sellerOrganizationMessage)}
                            </p>
                          )
                        }
                        end={inputSellerLoader ? <LoadingSpinner /> : ''}
                      />
                    </div>
                    {isAdmin && (
                      <Link to={'/'} title={t('Impersonate')}>
                        <IconButton
                          style={{ padding: 8, color: 'black' }}
                          onClick={() => {
                            const setCookies = new Promise((resolve) => {
                              setCookie('impersonateEmail', field.value, {
                                encode: (v) => v,
                              });
                              resolve(cookies['impersonateEmail']);
                            });
                            setCookies.then(() => {
                              navigate('/');
                            });
                          }}>
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                      </Link>
                    )}
                  </div>
                )}
              />
            </div>
          )}

          {user && user.isTrader && (
            <div className={styles.inputWrapper}>
              <InputLabel label={'Seller'} />
              <div className='readonly-input'>{sellerInfo.name}</div>
            </div>
          )}

          {user && user.isUser && (
            <div className={styles.inputWrapper}>
              <InputLabel label={'Seller'} />
              <EmptySelect placeholder={sellerInfo.name} />
            </div>
          )}

          {!isTrader && (isAdmin || isOrgAdmin || roleInDeal === 'Seller') ? (
            <div className={styles.inputWrapper}>
              <SellerAgents
                onValidateEmail={validateAgentEmail}
                buyerEmail={buyerEmailInfo}
                permissions={permissionsList}
              />
            </div>
          ) : null}

          {user &&
            (user.isAdmin || user.isOrgAdmin) &&
            traderLabel &&
            traders &&
            traders[0] && (
              <Controller
                name={`TraderId`}
                defaultValue={traderId || []}
                render={({ field }) => (
                  <SupportAgents
                    supportAgentsList={traders[0]}
                    permissions={permissionsList}
                  />
                )}
              />
            )}

          {user &&
            (user.isAdmin || user.isOrgAdmin) &&
            insuranceLabel &&
            insuranceAgents &&
            insuranceAgents[0] && (
              <div className={styles.inputWrapper}>
                <Controller
                  name={`InsurerId`}
                  defaultValue={insurerId || []}
                  render={({ field }) => (
                    <InsuranceAgents
                      insuranceAgentsList={insuranceAgents[0]}
                      permissions={permissionsList}
                    />
                  )}
                />
              </div>
            )}

          {user &&
            (user.isAdmin || user.isOrgAdmin) &&
            financeLabel &&
            financeAgents &&
            financeAgents[0] && (
              <div className={styles.inputWrapper}>
                <Controller
                  name={`FinancierId`}
                  defaultValue={financierId || []}
                  render={({ field }) => (
                    <FinanceAgents
                      financeAgentsList={financeAgents[0]}
                      permissions={permissionsList}
                    />
                  )}
                />
              </div>
            )}

          {user &&
            (user.isAdmin || user.isOrgAdmin) &&
            warehouseLabel &&
            warehouseAgents &&
            warehouseAgents[0] && (
              <div className={styles.inputWrapper}>
                <Controller
                  name={`WarehouseAgentId`}
                  defaultValue={warehouseAgentId || []}
                  render={({ field }) => (
                    <WarehouseAgents
                      warehouseAgentsList={warehouseAgents[0]}
                      permissions={permissionsList}
                    />
                  )}
                />
              </div>
            )}

          <div className='remaining-docs'>
            <div style={{ flex: 2 }}>
              {t('DocuSignRemainingDocumentSignatures')}:
            </div>
            <div style={{ flex: 0.7 }}>
              <Controller
                name={`RemainingDocusignSubmits`}
                defaultValue={deal?.RemainingDocusignSubmits || 0}
                render={({ field }) => (
                  <MuiOutlinedInput
                    {...field}
                    disabled={
                      (!isAdmin && !isOrgAdmin) ||
                      (field.value > 0 &&
                        !dirtyFields[`RemainingDocusignSubmits`])
                    }
                    required={false}
                    arrows
                    textAlign={'left'}
                    type={'number'}
                  />
                )}
              />
            </div>
          </div>

          <div
            className={`steps-container ${
              errors && errors.FormSteps ? 'error' : ''
            }`}>
            <p className='title'>{t('Select steps')}</p>
            {steps.length > 0 &&
              steps.map((item) => {
                return (
                  <label
                    key={item.StepId}
                    className={`step ${item.ReadOnly ? 'disabled' : ''} `}
                    onClick={
                      !item.ReadOnly
                        ? () => {
                            selectStep(item);
                          }
                        : undefined
                    }>
                    <span
                      className={`checkmark ${
                        selectedSteps.includes(item.StepId) ? 'checked' : ''
                      }`}>
                      <div className='check'>
                        <CheckmarkIcon />
                      </div>
                    </span>
                    <p>{t(item.StepName)}</p>
                  </label>
                );
              })}
            {errors && errors.FormSteps && (
              <p className='error-container'>{errors.FormSteps[0]}</p>
            )}

            {user && (user.isAdmin || user.isOrgAdmin) === true ? (
              <div className='reference-container'>
                <p className='references-titile title'>{t('References')}</p>
                {references && references.length > 0 ? (
                  references.map((reference, index) => {
                    return (
                      <div className='reference' key={index}>
                        <div className='reference-type'>
                          <p>{t('Type')}</p>
                          <SelectInput
                            chosen={reference.Type}
                            options={[t('Buyer'), t('Seller')]}
                            onChangeOptionHandler={(value) =>
                              onChangeReferenceType(index, value)
                            }
                          />
                        </div>
                        <div className='reference-value'>
                          <p>{t('Reference')}</p>
                          <input
                            value={reference.Reference}
                            onChange={(r) => {
                              onChangeReference(index, r.target.value);
                            }}
                            placeholder={t('Reference')}
                          />
                        </div>
                        <button
                          className='delete-ref'
                          title='Delete reference'
                          onClick={() => deleteReference(index)}>
                          <DeleteOutlineOutlinedIcon />
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p className='no-references'>
                    {t('There are no references for this deal')}.
                  </p>
                )}
                {references && cheIfDuplicateExists(references) && (
                  <div className={'field-error'}>
                    <FieldError
                      error={t('YouCannotHave2ReferenceNumbersForTheSameType')}
                    />
                  </div>
                )}
                <button
                  className='new-reference-button button'
                  onClick={addReference}>
                  {t('Add New Reference')}
                </button>
              </div>
            ) : (
              <div className='reference-container'>
                <p>{t('Reference')}</p>
                <input
                  value={myReference || ''}
                  onChange={(r) => {
                    setMyReference(r.target.value);
                  }}
                />
              </div>
            )}
          </div>
          {!deal.Draft && (
            <>
              <div className={'mute-mail--wrap'}>
                <div className={'mute__switcher'}>
                  <Switch
                    disabled={false}
                    onChange={() =>
                      setBlockchainVerification(!blockchainVerification)
                    }
                    checked={blockchainVerification}
                    onColor={variables.primaryBtnBgLight}
                    onHandleColor={variables.primaryBtnBgLight}
                    offColor='#999999'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={48}
                    handleDiameter={30}
                    className={'switcher'}
                  />
                </div>
                <div className={'mute__text'}>
                  {t('BlockchainVerification')}
                </div>
              </div>
              {user && (user.isAdmin || user.isOrgAdmin) && (
                <div className={'mute-mail--wrap'}>
                  <div className={'mute__switcher'}>
                    <Switch
                      onChange={() => setMuteMail(!muteMail)}
                      checked={muteMail}
                      onColor={variables.primaryBtnBgLight}
                      onHandleColor={variables.primaryBtnBgLight}
                      offColor='#999999'
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={48}
                      handleDiameter={30}
                      className={'switcher'}
                    />
                  </div>
                  <div className={'mute__text'}>{t('Mute Email messages')}</div>
                </div>
              )}
            </>
          )}
          {user && (user.isAdmin || user.isOrgAdmin) && (
            <div className={'mute-mail--wrap'}>
              <div className={'mute__switcher'}>
                <Switch
                  onChange={() => setShowContactInfo(!showContactInfo)}
                  checked={showContactInfo}
                  onColor={variables.primaryBtnBgLight}
                  onHandleColor={variables.primaryBtnBgLight}
                  offColor='#999999'
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={48}
                  handleDiameter={30}
                  className={'switcher'}
                />
              </div>
              <div className={'mute__text'}>{t('Show Contact Info')}</div>
            </div>
          )}
        </div>

        <div className='buttons-container'>
          <BackButton size={'medium'} />
          {loader ? (
            <div className='loader'>
              <LoadingSpinner />
              <p>LOADING...</p>
            </div>
          ) : (
            <Button
              onClick={handleSubmit(submit, (error) =>
                console.log('form error', error)
              )}
              size={'medium'}
              disabled={cheIfDuplicateExists(references)}>
              {t('Save')}
            </Button>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default EditDealComponent;
