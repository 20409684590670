/**
 * Accordion Component
 * @props title : the title of the accordion
 * @props children
 * @props defaultExpand: if the accordion will be open by default
 * @props type: type of the accordion
 * @props subTitle: If we need subtitle with a description inside.
 * @props description: description under the subtitle.
 * @props opened<number>: needed in order to open a specific accordion and close the other
 * @props index<number>: needed in order to mark the index of the current accordion for the close/open functionality
 * @props titleButton: button inside the title of the accordion next to the arrow
 * @props onClick: triggers and send to the parent when you toggle the accordion
 *
 * @desc Toggle Logic: the parent needs to have a state that keeps the index(1 | 2 | 3) of the current opened accordion
 * And send it to this component using the pros "opened".
 * inside the index props keep the index of the current accordion (1 | 2 | 3)
 * On accordion click: this component will trigger the onClick props,
 * and will send to the parent the state(false, true) of the current accordion
 * There the parent will update the current opened accordion: onClick={(value) => value && setOpenedAccordion(index)}
 * If the index and the opened values are equal(2===2) then the accordion is being opened and all the others are being closed.
 *
 * Usage: <SingleAccordionComponent
 * title={'Farm Details'}
 * opened={openAccordion}
 * index={i}
 * >{children}</SingleAccordionComponent>
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronDownIcon from '@assets/icons/ChevronDown';
import dealsService from '@lib/services/deals-service';
import styles from './form-accordion.module.scss';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import IconOnlyButton from '@atoms/Buttons/AddButton/add-button.component';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { usePopup } from '@lib/hooks/usePopup';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { useUserRole } from '@lib/hooks/useUserRole';

const FormAccordion = ({
  type = 'full',
  children,
  defaultExpand,
  description,
  subTitle,
  opened,
  index,
  onClick,
  closeAccordion,
  stepId,
  getAllDealDocumentsHandler,
}) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();
  const { deal, dealFlow } = useManageDealContext();

  const form = useForm({
    defaultValues: {
      ShouldBeApprovedBy: dealFlow === 'buy' ? 'seller' : 'buyer',
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const [openAccordion, isOpenAccordion] = useState(
    defaultExpand ? defaultExpand : false
  );

  // Open a specific accordion and close All others
  useEffect(() => {
    if (index && typeof opened == 'number') {
      if (opened === index) {
        isOpenAccordion(true);
      } else {
        isOpenAccordion(false);
      }
    }
  }, [index, opened]);

  useEffect(() => {
    if (typeof opened == 'boolean') {
      isOpenAccordion(opened);
    }
  }, []);

  const attachDocument = (formData, stepid) => {
    const payload = {
      Name: formData?.Name,
      ShouldBeApprovedBy: formData?.ShouldBeApprovedBy,
      Description: formData?.Description,
      AttachToStep: '1',
    };
    dealsService
      .attachDocumentToStep(stepid, payload)
      .then(() => {
        form.reset();
        getAllDealDocumentsHandler(deal?.Id);
        showPopup({
          state: 'success',
          message: t('DocumentAddedSuccessfully'),
          timer: 4000,
        });
      })
      .catch((e) => {
        showPopup({
          state: 'error',
          message: e.response && e.response.data.message,
          timer: 4000,
        });
      });
  };

  // Avoid repeating code
  const accordionTitleHtml = (titleClass) => {
    return (
      <>
        <div
          className={`
            ${titleClass === 'close' ? styles.opened : styles.closed} 
            ${styles.accordionHeader}
          `}>
          <ChevronDownIcon />
        </div>
      </>
    );
  };

  // if we have closeAccordion send as prop:
  // let the parent decide when to close the accordion
  const toggleAccordion = () => {
    if (closeAccordion === undefined || closeAccordion === true) {
      isOpenAccordion(!openAccordion);
      onClick && onClick(!openAccordion);
    } else {
      onClick && onClick(!openAccordion, true);
    }
  };

  return (
    <FormProvider {...form}>
      <div
        className={`${
          type === 'compact' ? styles.compactContainer : styles.container
        }`}
        style={{ paddingBottom: openAccordion ? '' : '5px' }}>
        <div className={styles.title}>
          <div className={styles.input}>
            <Controller
              name={`Name`}
              rules={{
                required: {
                  value: true,
                  message: t('Field is required'),
                },
              }}
              render={({ field }) => (
                <>
                  <MuiOutlinedInput
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                    }}
                    //label={t('Document name')}
                    value={field.value}
                    required={true}
                    disabled={false}
                    textAlign={'left'}
                    placeholder={t('Request Document')}
                    error={errors.Name}
                  />
                </>
              )}
            />
          </div>
          <div className={styles.addButton}>
            <IconOnlyButton
              handleClick={() => {
                handleSubmit(attachDocument)(stepId);
              }}
              tooltip={t('Add a new request document')}>
              <ControlPointOutlinedIcon />
            </IconOnlyButton>
          </div>
          <div
            onClick={() => {
              toggleAccordion();
            }}>
            <>
              {openAccordion
                ? accordionTitleHtml('close')
                : accordionTitleHtml()}
            </>
          </div>
        </div>
        {openAccordion && (
          <div className={styles.content}>
            {subTitle && <h4>{subTitle}</h4>}
            {description && (
              <div className={styles.description}>{description}</div>
            )}
            {children}
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default FormAccordion;
