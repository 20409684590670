export const activeDealsLink = "/";
export const archivedDealsLink = "/archived-deals";
export const draftDealsLink = "/draft-deals";
export const settingsLink = "/deal-settings";
export const createDealBuyLink = "/create-deal-buy";
export const createDealSellLink = "/create-deal-sell";

/* ========= Page Titles ========= */

export const activeDealsName = "Active Deals";
export const draftDealsName = "Draft Deals";
export const archivedDealsName = "Archived Deals";
export const settingsName = "Settings";
export const createDealName = "Create New Deal";

/* ========= White Label Org ========= */

export const proCafeWL = "https://procafe.com.br/en/";
