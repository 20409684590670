import React, { createContext, useContext, useMemo, useState } from "react";

export const useManageDealContext = () => {
  const context = useContext(ManageDealContext);
  if (!context) {
    throw new Error(
      "ManageDealContext must be used within a useManageDealProvider"
    );
  }
  return context;
};

const ManageDealContext = createContext();

export const ManageDealProvider = ({ children }) => {
  const [deal, setDeal] = useState(null);
  const [samples, setSamples] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [updatedDocuments, updateDocuments] = useState([]);
  const [servicesFees, setServicesFees] = useState([]);
  const [activePage, setActivePage] = useState("steps");
  const [activeService, setActiveService] = useState();
  const [allDealDocuments, setAllDealDocuments] = useState([]);
  const [documentApprovalSides, setDocumentApprovalSides] = useState({});
  const [channel, setChannel] = useState("deal");
  const [canWrite, setCanWrite] = useState(true);
  const [dealFlow, setDealFlow] = useState();

  const store = useMemo(
    () => ({
      deal,
      setDeal,
      samples,
      setSamples,
      selectedStep,
      setSelectedStep,
      documents,
      setDocuments,
      servicesFees,
      setServicesFees,
      channel,
      setChannel,
      canWrite,
      setCanWrite,
      allDealDocuments,
      setAllDealDocuments,
      updatedDocuments,
      updateDocuments,
      documentApprovalSides,
      setDocumentApprovalSides,
      dealFlow,
      setDealFlow,
    }),
    [
      deal,
      samples,
      selectedStep,
      documents,
      servicesFees,
      channel,
      canWrite,
      allDealDocuments,
      updatedDocuments,
      documentApprovalSides,
      dealFlow,
    ]
  );

  return (
    <ManageDealContext.Provider value={store}>
      {children}
    </ManageDealContext.Provider>
  );
};
