import { useRef, useEffect, memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import StyledDropzone from 'Components/Shared/StyledDropzone';
import Button from 'Components/Shared/Buttons/Button';
import { DocumentFile } from '../../Documents/DocumentFile/document-file.component';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import documentsService from '@lib/services/documents-service';
import { usePopup } from '@lib/hooks/usePopup';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { stepStatusCompleted } from '@lib/helpers/StepHelper/step-status.helper';
import FieldError from '@atoms/Fields/ReactFormFields/FieldError/field-error.component';

import styles from './upload-document.style.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';
import StyledDropzoneV2 from 'Components/Shared/StyledDropzoneV2/styled-dropzone-v2';

function Component({
  title,
  onClose,
  step,
  documentTypeId,
  documentId,
  isEdit,
  dealStepId,
  onRefreshDocuments,
  isLocked,
  type,
  documentType,
  //canWrite,
  serviceType,
  getAllDealDocumentsHandler,
  activePage,
  document,
}) {
  const { t } = useTranslation();
  const fileInput = useRef();
  const { showPopup } = usePopup();
  const { width } = useWindowSize();
  const {
    deal,
    documents,
    setDocuments,
    selectedStep: selectedStepContext,
    updateDocuments,
    canWrite,
  } = useManageDealContext();

  const [versions, setVersions] = useState(null);
  const [error, setError] = useState('');

  const assignNewDocument = (data) => {
    // if we are in an inner step like in PC
    if (data.Documents[0].DealStepId) {
      const newDocuments = documents.reduce((acc, curr) => {
        let current;
        if (curr.DealStepId === data.Documents[0].DealStepId) {
          current = data.Documents[0];
        } else {
          current = curr;
        }
        return [...acc, current];
      }, []);
      setDocuments(newDocuments);
      onRefreshDocuments && onRefreshDocuments(data.Documents);
      if (getAllDealDocumentsHandler) {
        getAllDealDocumentsHandler(deal.Id);
      }
    } else {
      setDocuments(data.Documents);
      if (getAllDealDocumentsHandler) {
        getAllDealDocumentsHandler(deal.Id);
      }
      onRefreshDocuments && onRefreshDocuments(data.Documents);
    }
    if (serviceType && updateDocuments) {
      updateDocuments(data.Documents);
    }
  };

  const uploadDocumentHandler = useCallback(
    (file) => {
      if (type === 'create') {
        const formData = new FormData();
        formData.append('File', file[0]);
        formData.append('DocumentType', documentType);

        documentsService
          .uploadCreateDocument(formData)
          .then((r) => {
            if (onRefreshDocuments && r.data?.Documents) {
              onRefreshDocuments(r.data?.Documents);
              setError('');
            } else if (r.data.Error) {
              setError(r.data.Error.Error);
            }
            showPopup({
              state: 'success',
              message: t('Document was uploaded successfully!'),
              timer: 4000,
            });
          })
          .catch((e) => {
            showPopup({
              state: 'error',
              message: e.response
                ? JSON.parse(e.response.data.message)
                : e.response.data.message,
              timer: 4000,
            });
          });
      } else {
        if (versions) {
          const formData = new FormData();
          formData.append('File', file[0]);
          documentsService
            .uploadDocumentVersion(documentId, deal.Id, formData)
            .then((r) => {
              assignNewDocument(r.data);
              showPopup({
                state: 'success',
                message: t(
                  `New version with name “{{name}}” was added for Document “{{document}}” in “{{step}}” section`,
                  {
                    name: r.data.Document.FileName,
                    document: r.data.Versions[0].FileName,
                    step: step.StepName,
                  }
                ),
                timer: 4000,
              });
            })
            .catch((e) => {
              if (e.response) {
                if (e.response.data.status === 500) {
                  showPopup({
                    state: 'error',
                    message: t(
                      `Server error! Please contact our support team.`
                    ),
                    timer: 4000,
                  });
                } else if (e.response.data.status === 422) {
                  showPopup({
                    state: 'error',
                    message: JSON.parse(e.response.data.message).File[0],
                    timer: 4000,
                  });
                } else {
                  showPopup({
                    state: 'error',
                    message: e.response.data.message,
                    timer: 4000,
                  });
                }
              } else {
                setError('Files bigger than 50MB can’t be attached.');
                showPopup({
                  state: 'error',
                  message: 'Files bigger than 50MB can’t be attached.',
                  timer: 4000,
                });
              }
            });
        } else {
          const formData = new FormData();
          formData.append('File', file[0]);
          formData.append('DealId', deal.Id);
          formData.append('DocumentTypeId', documentTypeId);
          if (serviceType !== undefined) {
            formData.append('ServiceType', String(serviceType));
          }
          dealStepId && formData.append('DealStepId', dealStepId);

          documentsService
            .uploadDocument(formData)
            .then((r) => {
              setError('');
              showPopup({
                state: 'success',
                message: t('Document was uploaded successfully!'),
                timer: 4000,
              });
              assignNewDocument(r.data);
            })
            .catch((e) => {
              if (e.response) {
                setError(JSON.parse(e.response.data.message) || 'error');
                showPopup({
                  state: 'error',
                  message: e.response
                    ? JSON.parse(e.response.data.message)
                    : e.response.data.message,
                  timer: 4000,
                });
              } else {
                setError('Files bigger than 50MB can’t be attached.');
                showPopup({
                  state: 'error',
                  message: 'Files bigger than 50MB can’t be attached.',
                  timer: 4000,
                });
              }
            });
        }
      }
    },
    [deal, documentTypeId, dealStepId, type]
  );

  const deleteVersionHandler = (documentId, versionId) => {
    documentsService
      .deleteDocumentVersion(deal.Id, documentId, versionId)
      .then(() => {
        return documentsService
          .getDocumentVersions(documentId, deal.Id)
          .then(({ data }) => {
            setVersions(data.Versions);
          });
      })
      .catch(console.error);
  };

  const statusCheck = () => {
    if (activePage === 'documents') {
      return stepStatusCompleted(step?.Status);
    } else {
      return stepStatusCompleted(selectedStepContext?.Status);
    }
  };

  useEffect(() => {
    isEdit &&
      documentsService.getDocumentVersions(documentId, deal.Id).then((r) => {
        setVersions(r.data.Versions);
      });
  }, []);

  return (
    <>
      {title && <h3>{t(title)}</h3>}
      {(isLocked && serviceType === undefined) ||
      (statusCheck() &&
        //activePage !== 'documents' &&
        serviceType === undefined) ? (
        <></>
      ) : (
        <>
          {activePage === 'documents' && !isEdit ? (
            <>
              <StyledDropzoneV2
                onDropHandler={uploadDocumentHandler}
                document={document}
                step={step}
              />
              <FieldError error={error}></FieldError>
            </>
          ) : (
            canWrite && (
              <>
                {width > 800 ? (
                  <>
                    <StyledDropzone onDropHandler={uploadDocumentHandler} />
                    <FieldError error={error}></FieldError>
                  </>
                ) : (
                  <>
                    <input
                      type='file'
                      name='file'
                      ref={fileInput}
                      style={{ display: 'none' }}
                      onChange={(e) =>
                        uploadDocumentHandler([e.target.files[0]])
                      }
                    />
                    <div className={styles.attachButton}>
                      <Button
                        type='empty'
                        size='medium'
                        onClick={() => {
                          fileInput.current.click();
                        }}>
                        {t('Attach File')}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )
          )}
          {/* {width > 800 ? (
            <>
              <StyledDropzone onDropHandler={uploadDocumentHandler} />
              <FieldError error={error}></FieldError>
            </>
          ) : (
            <>
              <input
                type='file'
                name='file'
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={(e) => uploadDocumentHandler([e.target.files[0]])}
              />
              <div className={styles.attachButton}>
                <Button
                  type='empty'
                  size='medium'
                  onClick={() => {
                    fileInput.current.click();
                  }}>
                  {t('Attach File')}
                </Button>
              </div>
            </>
          )} */}
        </>
      )}

      {!!versions?.length ? (
        versions.map((file) => (
          <DocumentFile
            key={file.VersionNumber}
            noEditAction={true}
            file={file}
            showStatus={true}
            onDeleteVersion={deleteVersionHandler.bind(
              this,
              file?.DocumentId,
              file?.VersionId
            )}
            onRefreshDocuments={onRefreshDocuments}
            canWrite={canWrite}
            step={step}
          />
        ))
      ) : (
        <>
          {isEdit && (
            <div>{t('Current document has no previous versions')}</div>
          )}
        </>
      )}
    </>
  );
}

export const UploadDocument = memo(Component);
